import React, { useState, useEffect } from "react"
import Index from "../components/Cities/index";

const storyblokAPI = process.env.GATSBY_APP_STORYBLOK_TOKEN
const storyblokVersion = process.env.GATSBY_APP_STORYBLOK_VERSION;

export default function Mo(props) {

  const [city, setCity] = useState({});
  const mainUrl = props.params["*"];
  const url = props.params["*"] ? props.params["*"].split("-") : [];
  const state = url[url.length-1];

  useEffect(() => {
    const api =
      `https://api.storyblok.com/v2/cdn/links/?starts_with=insureonline/city-pages/states/missouri/&token=` +
      storyblokAPI +
      "&version=" + storyblokVersion;
    fetch(api)
      .then((response) => response.json())
      .then((json) => {
        const links = json['links']
        const filteredlinks = Object.keys(links).filter(link => {
          return (links[link]['is_folder'] && links[link]['slug'].includes(mainUrl))
        });
      
        setCity(links[filteredlinks[0]]);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);
  return (
    city && (<Index
      city={city.name}
      state={state?.toUpperCase()}
      url={mainUrl ? mainUrl : "/index"}
    />)
  );
}
